// src/components/Navbar.js

import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

const NavigationBar = () => {
  const handleNavClick = () => {
    // Collapse the navbar after clicking a link on mobile
    const navbarToggler = document.querySelector('.navbar-toggler');
    const navbarCollapse = document.getElementById('navbarNav');
    if (navbarCollapse.classList.contains('show') && window.innerWidth < 992) {
      navbarToggler.click();
    }
  };

  return (
    <Navbar expand="lg" fixed="top" className="navbar navbar-dark">
      <Container fluid className="navbar-container">
        <Navbar.Brand href="#" className="navbar-brand">
          KH Solve
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNav" className="navbar-toggler" />
        <Navbar.Collapse id="navbarNav" className="collapse navbar-collapse">
          <Nav className="ms-auto navbar-nav">
            <Nav.Link href="#hero" className="nav-link" onClick={handleNavClick}>
              Home
            </Nav.Link>
            <Nav.Link href="#services" className="nav-link" onClick={handleNavClick}>
              Services
            </Nav.Link>
            <Nav.Link href="#about" className="nav-link" onClick={handleNavClick}>
              About Us
            </Nav.Link>
            <Nav.Link href="#contact" className="nav-link" onClick={handleNavClick}>
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;

