// src/App.js
import React from 'react';
import NavigationBar from './components/Navbar';
import HeroBanner from './components/HeroBanner';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './style.css';

function App() {
  return (
    <div>
      <NavigationBar />
      <HeroBanner />
      <Services />
      <AboutUs />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

