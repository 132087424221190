// src/components/Contact.js
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

const Contact = () => {
  // State variables to manage submission status and errors
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const form = e.target;
    const formData = new FormData(form);

    // Remove honeypot field if it's empty
    if (!formData.get('_gotcha')) {
      formData.delete('_gotcha');
    }

    fetch('https://formspree.io/f/mkgwdvra', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    })
      .then((response) => {
        setIsSubmitting(false);
        if (response.ok) {
          setIsSubmitted(true);
        } else {
          response.json().then((data) => {
            setError(data.errors?.[0]?.message || 'Something went wrong.');
          });
        }
      })
      .catch(() => {
        setIsSubmitting(false);
        setError('Something went wrong.');
      });
  };

  return (
    <section id="contact" className="contact" aria-labelledby="contact-heading">
      <Container>
        <h2 id="contact-heading">Contact Us</h2>
        <p>
          Book your <strong>free</strong> 1-hour consultation today.
        </p>
        <p>
          If you're ready to take your business to the next level, we'd love to hear from you. Whether you have a
          specific project in mind or just want to explore the possibilities, our team is here to help.
        </p>
        <form onSubmit={handleSubmit} className="contact-form" aria-label="Contact form">
          {/* Hidden input for form configuration */}
          <input type="hidden" name="_subject" value="New submission from KH Solve Contact Form" />
          <input type="hidden" name="_captcha" value="false" />

          {/* Honeypot field to prevent spam */}
          <input type="text" name="_gotcha" style={{ display: 'none' }} />

          {/* Form fields */}
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name<span className="sr-only"> (required)</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Your Name"
              required
              aria-required="true"
              disabled={isSubmitting || isSubmitted}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email<span className="sr-only"> (required)</span>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="_replyto"
              placeholder="Your Email"
              required
              aria-required="true"
              disabled={isSubmitting || isSubmitted}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">
              Message<span className="sr-only"> (required)</span>
            </label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              rows="5"
              placeholder="Your Message"
              required
              aria-required="true"
              disabled={isSubmitting || isSubmitted}
            ></textarea>
          </div>

          {/* Display error message if any */}
          {error && <div className="alert alert-danger">{error}</div>}

          <button
            type="submit"
            className={`btn btn-${isSubmitted ? 'success' : 'primary'}`}
            aria-label="Send Message"
            disabled={isSubmitting || isSubmitted}
          >
            {isSubmitted ? 'Submitted' : isSubmitting ? 'Submitting...' : 'Send Message'}
          </button>
        </form>
        <p className="mt-4">
          You can also reach us at <a href="mailto:contact@kh-solve.co.uk">contact@kh-solve.co.uk</a> or follow us
          on <a href="https://linkedin.com">LinkedIn</a>.
        </p>
      </Container>
    </section>
  );
};

export default Contact;

